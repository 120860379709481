import React from 'react';
import { graphql } from 'gatsby';
import styled from 'styled-components';

import Layout from '../components/layout';
import SEO from '../components/seo';

const StyledCard = styled.div`
  background: var(--glass-bg);
  border: var(--glass-border);
  box-shadow: var(--glass-glow);
  backdrop-filter: var(--glass-filter);
  border-radius: var(--border-radius);
  align-self: center;
  justify-self: center;

  padding: var(--spacer);
`;

export default function NotFoundPage({ data, location }) {
  const siteTitle = data.site.siteMetadata.title;

  return (
    <Layout location={location} title={siteTitle}>
      <SEO title="404: Not Found" />
      <StyledCard>
        <h1>Upps</h1>
        <p>
          Da ist wohl was schief gelaufen
          {' '}
          <span role="img" aria-label="enttäuschtes simley">
            😞
          </span>
        </p>
      </StyledCard>
    </Layout>
  );
}

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`;
